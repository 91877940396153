<template>
  <b-card
    v-if="Object.keys(generalInfo).length"
    class="integrator-service-general-info-card"
    header-class="general-card-header px-0"
  >
    <template #header>
      <p class="my-0 ml-2 title text-light">
        {{ $t('integrator.pages.edit_service.tabs.general.title') }}
      </p>
      <hr class="w-100"/>
    </template>

    <b-container fluid class="px-0">
      <b-row>
        <b-col v-for="field in inputFields" :key="field.label" cols="6">
          <view-edit-input
            :ref="`input-${field.label}`"
            :i18nKey="field.label"
            v-model="generalInfo[field.label]"
            :validator="field.rules"
            patchAction="serviceModule/patchIntegratorService"
            :dataForAction="patchActionData"
            @changed="$emit('changed')"
            @saved="$emit('saved', field.label)"
            @isSaving="(state) => $emit('isSaving', state)"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
           <b-form-group
              label-for="service-description"
            >
              <template #label>
                <div class="integrator-service-card-label">
                  {{ $t('integrator.pages.edit_service.tabs.general.description') }}
                </div>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="max:300"
              >
                <b-form-textarea
                  id="service-description"
                  :placeholder="$t('integrator.pages.edit_service.tabs.general.brief_desc')"
                  rows="5"
                  :state="errors.length > 0 ? false : null"
                  no-auto-shrink
                  no-resize
                  v-model.trim="generalInfo.short_description"
                  @input="$emit('changed')"
                />
                <p class="description-under-desc m-0 float-right" :class="errors.length ? 'text-danger' : 'text-muted'">{{ errors.length ? errors[0] : '300 letters max.' }}</p>
              </validation-provider>
            </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import {
  BCard,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend,
  BButton,
  BFormTextarea
} from 'bootstrap-vue'
import 'easymde/dist/easymde.min.css'
import { required, alphaDash } from '@validations'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ViewEditInput from '@/layouts/components/ViewEditInput.vue';
import { max } from '@/utils/validations'

  export default {
    components: {
      BCard,
      BContainer,
      BRow,
      BCol,
      BFormInput,
      BInputGroup,
      BFormGroup,
      BInputGroupAppend,
      BButton,
      BFormTextarea,
      ValidationProvider,
      ValidationObserver,
      ViewEditInput
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      serviceID: {
        type: [ Number, String ],
        required: true
      },
      orgID: {
        type: [ Number, String ],
        required: true
      }
    },
    data() {
      return {
        required, 
        max, 
        alphaDash,
        inputFields: [
          { label: 'host', rules: 'required|max:100'},
          // { label: 'identifier', rules: 'required|max:100'},
          { label: 'docs_uri', rules: 'max:100'},
        ]
      }
    },
    computed: {
      generalInfo: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      patchActionData() {
        return {
          serviceID: this.serviceID,
          orgID: this.orgID
        }
      }
    },
    methods: {
      setInputState(key, type, errors = new Array) {
        switch (type) {
          // case 'required': {
          //   return errors.length > 0 ? false : this.create_service[key] ? true : null
          // } break;
          case 'optional': {
            return this.create_service[key] ? true : null
          } break;
        }
      },
      generalUpdated() {
        this.inputFields.forEach((field) => {
          this.$refs[`input-${field.label}`][0].generalUpdated()
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

.integrator-service-general-info-card {
  .general-card-header {
    padding-top: 15px;
   .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.description-under-desc {
  font-size: 10px !important;
}
</style>