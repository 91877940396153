<template>
  <div>
  <b-row class="service-edit-header" v-if="service">
    <b-col cols="12">
      <b-card
        class=""
        body-class="service-edit-header-card-body d-flex"
      >
        <b-row class="w-100" no-gutters>
          <b-col cols="7" class="d-flex flex-wrap align-items-center">
            <div style="height: 100%; aspect-ratio: 1 / 1;">   
              <service-avatar
                size="140"
                :orgID="orgID"
                :serviceID="serviceID"
                serviceGetter="serviceModule/getIntegratorServiceByID"
                editable
                @saved="$emit('saved')"
              />  
            </div>         
            <div class="mx-1">
                <service-badges
                  :serviceID="serviceID"
                  :orgID="orgID"
                  :categories="service.integrator_category"
                  @saved="$emit('saved')"
                />
              <p class="service-edit-header-card-name text-light mb-50 mt-25">
                {{ service.name }}
                <feather-icon
                  id="edit-name-icon"
                  class="text-secondary cursor-pointer"
                  :size="iconSize"
                  icon="EditIcon"
                  @click="editName"
                />
                <input-edit-popover
                  :uuid="getID('edit-name')"
                  target="edit-name-icon"
                  :dataForAction="patchActionData"
                  i18nKey="name"
                  patchAction="serviceModule/patchIntegratorService"
                  validator="required|max:100"
                  v-model="service.name"
                  @saved="$emit('saved')"
                />
              </p>
              <p class="service-edit-header-card-category">
                {{ service.organization.name }}
              </p>
              <div class="mt-2">
                <button-privacy-popover
                  :serviceID="serviceID"
                  :orgID="orgID"
                  @saved="$emit('saved')"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="5" class="d-flex">
            <div class="service-edit-header-stats d-flex justify-content-around align-items-center">
              <div class="text-center pl-2">
                <feather-icon
                  icon="StarIcon"
                  size="22"
                  class="mb-75"
                />
                <p class="w-100 mb-50 metric-stat text-uppercase">
                  {{ getTotalRatings }} {{ $t("integrator.pages.edit_service.header.ratings") }}
                </p>
                <p class="w-100 stat">
                  {{ getRating }}
                </p>
              </div>
              <div style="border: 1px solid #343D55; transform: rotate(90deg); width: 90px; height: 0px;">
                
              </div>
              <div class="text-center pr-2">
                <feather-icon
                  icon="DownloadIcon"
                  size="22"
                  class="mb-75"
                />
                <p class="w-100 mb-50 metric-stat text-uppercase">
                  {{ $t("integrator.pages.edit_service.header.downloads") }}
                </p>
                <p class="w-100 stat">
                  {{ getDownloads }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
  
  <b-row class="mb-2" v-else>
    <b-col md="12" class="py-0">
      <b-card class="mr-1 my-0">
        <div class="d-flex align-items-center">
          <b-skeleton height="150px" width="150px" class="mr-2"/>
          <div class="w-50">
            <div class="d-flex">
              <b-skeleton height="15px" width="40px"/>
              <b-skeleton height="15px" width="25px" class="mx-1"/>
            </div>
            <b-skeleton height="20px" width="150px"/>
            <b-skeleton height="15px" width="70px" class="mt-2"/>
            <b-skeleton height="40px" width="180px" class=""/>
          </div>
          <div class="d-flex align-items-center justify-content-left w-50 pl-2">
            <div v-for="i in 2" :key="'header-service-edit-' + i" class="text-center d-flex align-items-center">

              <div>
                <div class="d-flex justify-content-center">
                  <b-skeleton height="20px" width="25px" class="mx-1"/>
                </div>

                <b-skeleton height="15px" width="100px" class="mx-1"/>

                <div class="d-flex justify-content-center mt-2">
                  <b-skeleton height="25px" width="40px" class="mx-1"/>
                </div>
              </div>
              
              <b-skeleton v-if="i==1" height="100px" width="5px" class="mx-2"/>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</div>
</template>

<script>
import {
  BCard,
  BContainer,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BButton,
  BDropdown,
  BBadge,
  BSkeleton,
} from 'bootstrap-vue'
import ServiceCover from '@/views/pages/integrator/components/General/Cover.vue';
import ServiceAvatar from '@/views/pages/integrator/components/General/Avatar.vue';
import ServiceBadges from '@/views/pages/integrator/components/General/Badges.vue';
import ButtonPrivacyPopover from '@/views/pages/integrator/components/EditService/ButtonPrivacyPopover.vue';
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import InputEditPopover from '@/views/pages/integrator/components/EditService/InputEditPopover.vue'

export default {
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    BImg,
    BAvatar,
    BButton,
    BDropdown,
    BBadge,
    ServiceAvatar,
    ServiceCover,
    ServiceBadges,
    ButtonPrivacyPopover,
    InputEditPopover,
    BSkeleton,
  },
  props: {
    orgID: {
      type: [ Number, String ],
      required: true
    },
    serviceID: {
      type: [ Number, String ],
      required: true
    },
  },
  data() {
    return {
      iconSize: '16',
      uuidMap: {},
      patchActionData: { serviceID: this.serviceID, orgID: this.orgID }
    }
  },
  computed: {
    ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
    service() {
      let r = this.getIntegratorServiceByID(this.orgID, this.serviceID)

      if (r && Object.keys(r).length == 0){
        return undefined
      }
      return r
    },
    isPublic() {
      return this.service.public
    },
    getDownloads() {
      return this.service.downloads_counter.toLocaleString()
    },
    getRating() {
      return this.service.average_rating.toFixed(1).toLocaleString()
    },
    getTotalRatings() {
      return this.service.total_ratings || '1K'
      if (this.service.total_ratings > 1000) {
        return `${this.service.total_ratings.toFixed(1)}K`
      } else {
        return this.service.total_ratings
      }
    },
  },
  methods: {
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      } 

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    editName() {
      this.$root.$emit("bv::show::popover", this.getID('edit-name'));
    }
  },
}
</script>

<style lang="scss" scoped>

.force-w-100{
  width: 100% !important; 
  max-width: 100% !important; 
  min-width: 100% !important;
}

.service-edit-header {
  .service-edit-header-card-body {
    height: 190px !important;
  }
  .service-edit-header-card-body {
    padding: 25px;
  }

  .service-edit-header-card-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
  .service-edit-header-card-category {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #B4B7BD;
  }

  .service-edit-header-stats {
    .metric-stat {
      font-weight: 500; 
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.366667px;
    }

    .stat {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
    }
  }
}
</style>