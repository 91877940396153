<template>
  <div class="folder-item cursor-pointer">
    <!-- <vue-perfect-scrollbar style="max-height: 560px;"> -->
      <div v-for="(group, index) in groups" :key="group.label + index">
        <b-row
          align-v="center"
          class="d-flex justify-content-between folder-item-toggler m-0 flex-nowrap"
          :class="selected == index ? 'highlight' : ''"
          @click.self="toggleGroup(index)"
        >
          <div class="set-width-90" @click.self="toggleGroup(index)">
            <div class="folder-item-markup"></div>
            <feather-icon
              class="folder-item-toggler-icon"
              :icon="!openGroup[index] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="16"
              @click.self="toggleGroup(index)"
            />
            <b-icon
              class="folder-item-toggler-icon ml-25"
              :icon="!openGroup[index] ? 'folder2-open' : 'folder'"
              scale="1.2"
              @click="toggleGroup(index)"
            />
            <span v-if="!(editingIndex == index)" class="ml-25 folder-item-toggler-label" @click.self="toggleGroup(index)">
              {{ shorttenedString(group.label, 17) }}
            </span>
            <b-form-input
              v-else
              :ref="getID(`group-label-${index}`)"
              class="folder-item-group-input d-inline ml-50 p-50"
              v-model.trim="editingLabel"
              maxlength="30"
              type="text"
              @keydown.enter="editLabel(index)"
              @blur="editLabel(index); editingIndex = null"
            />
          </div>
          <b-dropdown
            no-caret
            variant="outline-none"
            toggle-class="py-0 folder-item-dropdown integrator-folder-nav-dropdown d-inline mr-50"
            right
            menu-class="bg-light"
            :disabled="loadingGroupObj[index]"
            @click="toggleGroup(index, true)"
          >
            <template #button-content>
              <b-spinner
                v-if="loadingGroupObj[index]"
                style="width: 1rem; height: 1rem"
              />
              <feather-icon
                v-else
                class="folder-item-toggler-dots"
                icon="MoreVerticalIcon"
                size="16"
                @click="toggleGroup(index, true)"
              />
            </template>
            <div v-for="item in options" :key="item.label">
              <b-dropdown-item
                v-if="!item.divider"
                class="folder-item-dropdown-item integrator-folder-nav-dropdown-item"
                @click="item.action(group, index)"
              >
                <feather-icon
                class="mr-50"
                :icon="item.icon"
                size="20"
                />
                {{ $t(item.label) }}
              </b-dropdown-item>
              <b-dropdown-divider v-else/>
            </div>
          </b-dropdown>
        </b-row>
        <b-collapse :visible="!openGroup[index]">
          <folder-item
            :ref="getID(`folder-item-${index}`)"
            v-model="groups[index].requests"
            :serviceID="serviceID"
            :groupID="group.id"
            :enumSetupType="enumSetupType"
            @setSelected="(item) => setSelected(index, item, group.id)"
            @isMounted="$emit('isMounted')"
          />
        </b-collapse>
      </div>
    <!-- </vue-perfect-scrollbar> -->
    <div v-if="!groups || creatingGroupArr.length" class="m-25">
      <div v-for="(i, index) in getLoadingFolderList" :key="`folder-group-skeleton-${index}`" class="d-flex align-items-center" style="height: 34px">
        <div
          class="mr-25 mb-0 mt-0 rounded"
        >
          <b-skeleton-icon
            icon="chevron-right"
            :icon-props="{ fontScale: 0.9, variant: 'secondary' }"
          />
        </div>
        <div
          class="mr-25 mb-0 mt-0 round"
        >
          <b-skeleton-icon
            class="ml-25"
            icon="folder"
            :icon-props="{ fontScale: 1.2, variant: 'secondary' }"
          />
        </div>
        <b-skeleton
          class="ml-25 mt-50"
          height="21px"
          width="80%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
  BSkeleton,
  BSkeletonIcon,
  BSpinner
} from 'bootstrap-vue'
import FolderItem from '@/views/pages/integrator/components/EditService/Endpoint/FolderItem.vue'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { makeToast } from '@/layouts/components/Popups'
import EndpointSetupType from '@/views/pages/integrator/components/EditService/Endpoint/class/Enum/EndpointSetupType.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  export default {
    components: {
      FolderItem,
      BRow,
      BCol,
      BCollapse,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      BFormInput,
      BSkeleton,
      BSkeletonIcon,
      VuePerfectScrollbar,
      BSpinner
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true,
      },
      enumSetupType: {
        type: [ Number, String ],
        required: true
      }
    },
    data() {
      return {
        openGroup: new Array(),
        selected: null,
        options: [
          { label: this.$t('integrator.pages.edit_service.tabs.operations.new_request'), icon: 'PlusCircleIcon', action: this.addRequest },
          { divider: true },
          { label: this.$t('integrator.pages.edit_service.tabs.operations.edit_label'), icon: 'EditIcon', action: this.showEdit },
          { label: this.$t('integrator.pages.edit_service.tabs.operations.delete_group'), icon: 'TrashIcon', action: this.deleteGroup },
        ],
        version: 0,
        uuidMap: new Object(),
        editingLabel: null,
        editingIndex: null,
        setupType: null,
        creatingGroupArr: new Array(),
        loadingGroupObj: new Object()
      }
    },
    computed: {
      ...mapGetters('requestModule', ['getGroupRequestByServiceID']),
      ...mapGetters('webhookModule', ['getGroupWebhookByServiceID']),
      groups() {
        if (!this.setupType) return []
        return this[this.setupType.groupGetter](this.serviceID)
      },
      getLoadingFolderList() {
        if (this.creatingGroupArr.length) {
          return this.creatingGroupArr
        }
        return Math.max(3, Math.floor(10 * Math.random()))
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setSetupType()
        if (!this.groups) {
          this.getIntegratorGroupByServiceID()
        } else {
          this.$emit('isMounted')
        }
      },
      setSetupType() {
        let typeItems = new EndpointSetupType().items
        this.setupType = typeItems.find(el => el.id == this.enumSetupType)
      },
      getIntegratorGroupByServiceID() {
        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.getGroups}`, this.serviceID).then((resp) => {
          if (!resp.length) {
            this.$emit('isMounted')
          }
        }).catch((error) => {
          console.error(error)
        })
      },
      filterRequest(string) {
        this.openGroup.forEach((_, index) => {
          this.$set(this.openGroup, index, true)
        })
        this.groups.forEach((_, index) => {
          this.$refs[this.getID(`folder-item-${index}`)][0].filterRequest(string)
        })
      },
      toggleGroup(index, state = null) {
        this.$set(this.openGroup, index, state == null ? !this.openGroup[index] : state)
      },
      addRequest(_, index) {
        let pos = index || this.selected || 0
        
        if (!this.$refs[this.getID(`folder-item-${pos}`)]) {
          makeToast({
            title: this.$t('integrator.pages.edit_service.tabs.operations.toasts.group_before_request.title'),
            text: this.$t('integrator.pages.edit_service.tabs.operations.toasts.group_before_request.text'),
            variant: 'danger',
            icon: "XIcon"
          })
          return
        }
        
        this.$refs[this.getID(`folder-item-${pos}`)][0].addRequest()
        this.toggleGroup(pos, false)
      },
      showEdit(item, index) {
        this.editingIndex = index;
        this.editingLabel = item.label

        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs[this.getID(`group-label-${index}`)][0]?.select()
          }, 10)
        })
      },
      editLabel(index) {
        if (this.editingLabel == this.groups[this.editingIndex].label)  {
          this.editingIndex = null
          return
        }
        this.editingIndex = null
        this.$set(this.loadingGroupObj, index, true)

        this.$store.dispatch(`${this.setupType.namespace}patchGroup`,
          {
            field: 'label',
            groupID: this.groups[index].id,
            serviceID: this.serviceID,
            new_value: this.editingLabel
          }
        ).then(() => {
          this.$set(this.loadingGroupObj, index, false)
        }).catch((err) => {
          console.error(err)
          this.$set(this.loadingGroupObj, index, false)
        })
      },
      addGroup() {
        let pos = this.creatingGroupArr.push(true) - 1
        this.$store.dispatch(`${this.setupType.namespace}addGroup`, 
          {
            serviceID: this.serviceID,
            label: this.$t('integrator.pages.edit_service.tabs.operations.new_group')
          }
        ).then(() => {
          let index = this.groups.length - 1
          
          this.$nextTick(() => {
            this.creatingGroupArr.pop()
            this.showEdit(this.groups.at(-1), index)
          })

        }).catch((err) => {
          console.error(err)
          this.creatingGroupArr.splice(pos, 1)
        })
        this.version++
      },
      deleteGroup(group, index) {
        this.$set(this.loadingGroupObj, index, true)
        this.$store.dispatch(`${this.setupType.namespace}deleteGroup`, 
          {
            serviceID: this.serviceID,
            groupID: group.id,
          }
        ).then(() => {
          this.$set(this.loadingGroupObj, index, false)

        }).catch((err) => {
          console.error(err)
          this.$set(this.loadingGroupObj, index, true)
        })
        this.version++
      },
      setSelected(index, item, groupID) {
        this.setActiveGroup(index)
        this.$emit('setSelected', { item, groupID })
        this.unsetSelectedRequest(index)
      },
      unsetSelectedRequest(index) {
        this.groups.forEach((_, key) => {
          if (key != index) {
            this.$refs[this.getID(`folder-item-${key}`)][0].setSelectedItem(null)
          }
        })
      },
      setActiveGroup(index) {
        this.selected = index
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      shorttenedString(str, size) {
        if(str.length > size) {
          str = str.substring(0, size) + '...';
        }
        return str
      }
    },
  }
</script>

<style lang="scss" scoped>

.folder-item {
  min-height: 34px;
  .folder-item-markup {
    position: absolute;
    border-radius: 1.5px;
    border-left: 3px solid #7367F0;
  }
  .folder-item-toggler-dots {
    opacity: 0;
    transition: all 0.2s ease;
  }
  &:hover {
    .folder-item-markup {
      opacity: 1;
    }
    .folder-item-toggler-dots {
      color: #FFFFFF;
      opacity: 1;
    }
  }
  .folder-item-toggler {
    height: 34px;
    &.highlight {
      background: #343D55;
      .folder-item-toggler-icon, .folder-item-toggler-label{
        color: #FFFFFF;
        transition: all 0.2s ease;
      }
    }
    &:hover{
      background: #343D55;
      .folder-item-toggler-icon, .folder-item-toggler-label{
        color: #FFFFFF;
        transition: all 0.2s ease;
      }
    }
    .folder-item-toggler-icon {
      color: #949bb1;
      transition: all 0.2s ease;
    }
    .folder-item-toggler-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.4px;
      color: #949bb1;
      transition: all 0.2s ease;
    }
  }
}
</style>

<style lang="scss">
.folder-item-group-input {
  height: 20px !important;
  width: 60% !important;
}
</style>