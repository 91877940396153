<template>
  <div class="position-absolute m-0 p-0 header-card-cover shadow">
    
    <b-avatar class="header-card-cover-img" rounded>
      <b-img
        v-if="service.cover_uri"
        id="service-cover"
        :src="service.cover_uri"
      />
      <div v-else class="placeholder-cover-img rounded bg-purple d-flex align-items-center justify-content-center shadow">
        
      </div>

    </b-avatar>
  </div>
</template>

<script>
import { BAvatar, BTooltip, BFormFile, BImg } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

  export default {
    components: {
      BAvatar,
      BTooltip,
      BFormFile,
      BImg
    },
    props: {
      length: {
        type: [ String, Number ],
        default: null
      },
      height: {
        type: [ String, Number ],
        default: null
      },
      editable: {
        type: Boolean,
        default: false
      },
      orgID: {
        type: [ String, Number ],
        required: true
      },
      serviceID: {
        type: [ String, Number ],
        required: true
      }
    },
    data() {
      return {
        version: 0,
      }
    },
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
      service() {
        return this.getIntegratorServiceByID(this.orgID, this.serviceID)
      },
      getAvatarString() {
        let arr = this.service.name.split(/[_,-\s]/)
        
        if (arr.length < 2) {
          return arr[0].substring(0, 2).toUpperCase()
        } else {
          return `${arr[0][0]}${arr[1][0]}`.toUpperCase()
        }
      },
    },
    methods: {
      onFileChange(e, target) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        var reader = new FileReader();
        reader.onload = (event) => {
          this.$store.dispatch('serviceModule/patchIntegratorService', 
            { 
              serviceID: this.serviceID,
              orgID: this.orgID,
              field: target,
              new_value: e.target.files[0].name
            }).then(() => {
              this.$store.commit('serviceModule/PATCH_INTEGRATOR_SERVICE_PARAM', {
                param: target,
                orgID: this.orgID,
                serviceID: this.serviceID,
                new_value: event.target.result,
              })
              this.$emit('saved')
            }).catch((error) => {
              console.log('f')
            })
        };
        this.version++
        // this.card[target].append(target, e.target.files[0], e.target.files[0].name);
        // this.card[target].append(target, e.target.files[0], e.target.files[0].name);

        reader.readAsDataURL(files[0]);
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.general-service-avatar {
  transition: all 0.3s ease;
  .general-service-avatar-edit {
    top: 5px !important;
    right: 30px !important;
    .edit-avatar-icon {
      color: #D0D2D6; 
      transition: all 0.3s ease;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.3s ease;
    .edit-avatar-icon {
      color: $backgroundDark !important;
      transition: all 0.3s ease;
    }
  }
}
</style>

<style lang="scss">
.header-card-cover {
  .header-card-cover-img {
    height: 190px !important;
    width: 100% !important;
    position: relative;
  }
  .custom-file-label {
    cursor: pointer;
    height: 190px;
    width: 100% !important;
  }

  .form-file-text {
    opacity: 0;
    height: 190px;
  }

  .placeholder-cover-img {
    width: 100%;
    height: 190px;
  }
}


</style>