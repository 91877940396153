<template>
  <b-container fluid>
    <div align='right'>
      <router-link :to="`/integrator/service/${service.identifier}/view`" v-if="service">
        <b-button variant="outline-purple" class="view-service-btn">
          <b-icon icon="eye" scale="1.15" class="mr-50"/>
          {{ $t("integrator.pages.edit_service.header.view_service") }}
        </b-button>
      </router-link>
    </div>
    <service-header
      :serviceID="serviceID"
      :orgID="orgID"
      @saved="dataChanged"
    />
    <b-row class="mb-1">
      <b-col>
        <service-nav
          v-model="nav"
        />
      </b-col>
    </b-row>
    <b-row v-if="nav[0]">
      <b-col cols="12">
        <general-info
          ref="general-info"
          :serviceID="serviceID"
          :orgID="orgID"
          v-model="generalInfo"
          @changed="verifyChange"
          @saved="singlePatched"
          @isSaving="setIsSaving"
        />
        <readme-edit 
          v-if="generalInfo && generalInfo.id"
          v-model="generalInfo.readme"
          @changed="verifyChange"
          @saved="singlePatched"
        />
      </b-col>
      <b-button
        v-if="infoChanged"
        class="position-fixed button-save-changes animate__animated animate__fadeInUp"
        :disabled="isSaving"
        variant="relief-primary"
        size="md"
        pill
        @click="submit"
      >
        <span
          v-if="isSaving"
        >
          <b-spinner
            small
          />
          <b>
            {{ $t("integrator.pages.edit_service.saving") }}
          </b>
        </span>
        <b v-else>
          {{ $t("integrator.pages.edit_service.save") }}
        </b>
      </b-button>
    </b-row>
    <b-row v-if="nav[2]">
      <b-col cols="12">
        <endpoint-management
          key="management-requests"
          :serviceID="serviceID"
          enumSetupType="1"
        />
      </b-col>
    </b-row>
    <b-row v-if="nav[3]">
      <b-col cols="12">
        <endpoint-management
          key="management-webhook"
          :serviceID="serviceID"
          enumSetupType="2"
        />
      </b-col>
    </b-row>
    <b-row v-if="nav[4]">
      <b-col cols="12">
        <releases-info :serviceID="serviceID"></releases-info>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import { 
  BContainer,
  BRow,
  BCol,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import ServiceHeader from '@/views/pages/integrator/components/EditService/Header.vue'
import ServiceNav from '@/views/pages/integrator/components/EditService/Nav.vue'
import GeneralInfo from '@/views/pages/integrator/components/EditService/GeneralInfo.vue'
import ReadmeEdit from '@/views/pages/integrator/components/EditService/ReadmeEdit.vue'
import EndpointManagement from '@/views/pages/integrator/components/EditService/Endpoint/Management.vue'
import { mapGetters } from 'vuex'
import 'animate.css';
import { makeToast } from '@/layouts/components/Popups'
import ReleasesInfo from '@/views/pages/integrator/components/EditService/ReleasesInfo.vue'

  export default {
    components: {
      ServiceHeader,
      BContainer,
      BRow,
      BCol,
      BButton,
      ServiceNav,
      GeneralInfo,
      ReadmeEdit,
      BSpinner,
      EndpointManagement,
      ReleasesInfo,
    },
    data() {
      return {
        nav: [],
        infoChanged: false,
        isSaving: false,
      }
    },  
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
      serviceID() {
        return this.$route.params.serviceID;
      },
      orgID() {
        return localStorage.getItem('selectedOrganization');
      },
      service() {
        return this.getIntegratorServiceByID(this.orgID, this.serviceID)
      },
      generalInfo(){
        if (!this.service){
          return {}
        }
        return structuredClone(this.service)
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        // this.getServicesByOrganization()
        this.getServiceById()
      },
      getServiceById(){
        this.$store.dispatch('serviceModule/fetchServiceByID', this.serviceID).then((resp)=>{
        })
      },
      getServicesByOrganization() {
        this.$store.dispatch('serviceModule/fetchIntegratorServicesByOrganization', {organizationID: this.orgID})
        .then(() => {})
        .catch((err) => {
          console.error(err)
          makeToast({
            title: 'Error',
            text: "Couldn't fetch the Service",
            variant: 'danger',
            icon: 'XIcon'
          })
        })
      },
      singlePatched() {
        this.verifyChange()
      },
      fullUpdated() {
        this.$refs['general-info'].generalUpdated()
        this.verifyChange()
      },
      verifyChange() {
        this.infoChanged = this.compareObjects(this.service, this.generalInfo)
      },
      submit() {
        const { name, organization, identifier, host, image_uri, cover_uri, docs_uri, integrator_category, readme, short_description, } = this.generalInfo
        
        if (name && host) {  
          
          this.setIsSaving(true)
          this.$store.dispatch('serviceModule/updateIntegratorService', 
            { 
              data: {
                name,
                organization: organization.id,
                identifier,
                host,
                image_uri,
                cover_uri,
                docs_uri,
                public: this.generalInfo.public,
                integrator_category: integrator_category.id,
                readme,
                short_description,
              },
              serviceID: this.serviceID,
              orgID: this.orgID
            }
          ).then(() => {
            this.setIsSaving(false)
            this.fullUpdated()
            this.infoChanged = false
            makeToast({
              title: 'Success!',
              text: 'Service updated successfully',
              variant: 'success',
              icon: 'CheckIcon'
            })
          }).catch((error) => {
            this.setIsSaving(false)
            makeToast({
              title: '',
              text: '',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
        }
      },
      compareObjects(obj1, obj2) {
        const ignoredFields = [
          'updated_at'
        ]

        if (!obj1 || !obj2) return;

        const keys1 = Object.keys(obj1), keys2 = Object.keys(obj2);

        var isDifferent = false;

        if (keys1.length != keys2.length) return isDifferent = true;

        keys1.forEach((_, index) => {
          let key1 = keys1[index],
              key2 = keys2[index];
          if (ignoredFields.includes(key1) || ignoredFields.includes(key2)) return

          if (this.isObject(obj1[key1]) && this.isObject(obj2[key2])) {
            isDifferent = this.compareObjects(obj1[key1], obj2[key2]);
          } 
          else {  
            if (key1 != key2) {
              isDifferent = true;
            }
            if (obj1[key1] != obj2[key2]) {
              isDifferent = true;
            }
          }
        })
        return isDifferent;
      },
      isObject(item) {
        return (item instanceof Object && !(item instanceof Array));
      },
      setIsSaving(state) {
        this.isSaving = state
      },
      dataChanged() {
        // this.setGeneralInfo()
      },
      // setGeneralInfo() {
      //   this.generalInfo = structuredClone(this.service)
      // }
    },
  }
</script>

<style lang="scss" scoped>
.button-save-changes {
  right: calc(3% - 10px);
  top: 93%; 
  z-index: 3;
}

.view-service-btn{
  position: absolute;
  right: 40px;
  top: 30px;
}
</style>